import React, { useRef } from "react";
import { motion } from "framer-motion";

import videoTest from "assets/images/landingAir/rejoice-video/rejoice-video.mp4";

/**
 * @returns {JSX.Element}
 * @constructor
 */

const RejoiceVideo = () => {
  return (
    <section className="rejoice-video">
      <div className="container">
        <motion.video
          className="rejoice-video__bg"
          src={videoTest}
          autoPlay
          muted
          loop
          playsInline
          initial={{ opacity: 0 }}
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
        />
        <div className="rejoice-video__content">
          <motion.h2
            className="rejoice-video__title"
            initial={{ opacity: 0, y: 20 }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.02 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
          >
            Air breathers, rejoice.
          </motion.h2>
          <motion.div
            className="rejoice__description"
            initial={{ opacity: 0, y: 20 }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.03 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
          >
            <p>
              Mila is on a mission to bring every family out of airblivion and
              into a healthier home.
            </p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default RejoiceVideo;
